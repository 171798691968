import { createTheme } from "@mantine/core";
// Helper function to generate color shades
import { generateColors } from "@mantine/colors-generator";

export const BRAND_COLOR = "#00AFE1";
export const ORANGE_COLOR = "#EF4E22";
export const NAVY_COLOR = "#09294E";
export const YELLOW_COLOR = "#FFB410";

export const myTheme = createTheme({
  // fontFamily:
  //   "Raleway, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji",
  colors: {
    brand: generateColors(BRAND_COLOR),
    orange: generateColors(ORANGE_COLOR),
    navy: generateColors(NAVY_COLOR),
    yellow: generateColors(YELLOW_COLOR),
  },
  primaryColor: "navy",
});
